.user_profile-photo-wrapper {
  height: 250px;
  width: 250px;

  position: relative;

  label {
    width: inherit;
    height: inherit;
  }

  .user_avatar {
    width: inherit;
    height: inherit;
    cursor: pointer;
    position: relative;

    &::before {
      content: '';
      width: 250px;
      height: 250px;
      background-color: rgba(#ddd, 0.75);
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  .update_photo-btn {
    display: none;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: #fff;

    &:hover {
      background-color: #fff;
    }
  }

  &:hover {
    .update_photo-btn {
      display: inline-flex;
    }

    .user_avatar::before {
      opacity: 1;
    }
  }
}
