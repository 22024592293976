html {
  height: 100%;
}

body {
  height: inherit;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: inherit;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

tr {
  height: 50px !important;
}

/* tr > td:not(:last-of-type) {
  border-right: 1px solid rgba(224, 224, 224, 1);
}

tr > .MuiTableCell-body {
  border-right: 1px solid rgba(224, 224, 224, 1);
}

tr > .MuiTableCell-head:not(:last-of-type) {
  border-right: 1px solid rgba(224, 224, 224, 1);
} */

#settings-page .MuiAutocomplete-root .Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 0;
  border-radius: 0;
}

#settings-page .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon > div::after {
  content: '';
  border-bottom: 2px solid #1976d2;
  left: 0;
  bottom: 0;
  position: absolute;
  right: 0;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

#settings-page .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.Mui-focused > div::after {
  transform: scaleX(1) translateX(0);
}

th {
  max-width: 65px;
  min-width: 65px;
  padding: 0 !important;
  overflow-x: auto;
  white-space: nowrap;
  text-align: center !important;
  font-weight: 700 !important;
}

td {
  height: 50px !important;
  padding: 0 10px !important;
  white-space: nowrap;
}

.full-col-flex-link {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

@keyframes spooky {
  from {
    transform: translateY(0.15em) scaleY(0.95);
  }

  to {
    transform: translateY(-0.15em);
  }
}

.rsw-ce {
  max-height: 300px;
  overflow: scroll;
}
