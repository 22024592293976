.navigation-link {
  text-decoration: none;
  color: inherit;
  display: block;
  &.active {
    background-color: rgba(#ccc, 0.4);
  }
}

main {
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}
