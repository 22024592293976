$colors: (
  'primary': #c30109,
  'secondary': #0d3b59,
  'grey': #f0f3f5,
  'darkGrey': #9eb1bc,
  'disabled': #e4e4e4,
);

.Mui-disabled {
  background-color: map-get($colors, 'disabled') !important;
  cursor: not-allowed !important;
  border-radius: 10px !important;
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-radius: 10px;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: map-get($colors, 'secondary') !important;
}

.MuiTabs-indicator {
  background-color: map-get($colors, 'secondary') !important;
}

.Mui-focused {
  color: map-get($colors, 'secondary') !important;
}

.MuiAccordion-root {
  background-color: map-get($colors, 'grey') !important;
  border-radius: 10px !important;
}

.MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-content .MuiTypography-root {
  font-family: 'Days One', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  color: map-get($colors, 'secondary');
}

.MuiAutocomplete-root,
.MuiInputBase-root {
  background-color: #fff;
  border-radius: 10px !important;
  // font-family: 'Lato', sans-serif !important;
}

.MuiTable-root {
  border: 1px solid map-get($colors, 'darkGrey');
  border-bottom: none;
  td,
  th {
    font-family: 'Lato', sans-serif !important;
  }
}

.MuiTableHead-root {
  th {
    background-color: map-get($colors, 'grey');
    border-bottom: 1px solid map-get($colors, 'darkGrey');
    &:not(:last-of-type) {
      border-right: 1px solid map-get($colors, 'darkGrey');
    }
  }
}

.MuiTableBody-root {
  td,
  th {
    border-bottom: 1px solid map-get($colors, 'darkGrey');
    &:not(:last-of-type) {
      border-right: 1px solid map-get($colors, 'darkGrey');
    }
  }
}

.MuiTablePagination-root {
  .MuiSelect-select {
    background-color: map-get($colors, 'grey') !important;
  }

  .MuiTablePagination-actions {
    .Mui-disabled {
      background-color: transparent !important;
    }
  }
}

.MuiDrawer-paper {
  background-color: map-get($colors, 'grey') !important;

  & > div {
    max-width: 500px;
    width: 100%;
  }

  .MuiTypography-root {
    font-family: 'Days One', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: map-get($colors, 'secondary');
  }

  .MuiGrid-container {
    padding: 16px;
    padding-top: 80px;
  }

  .MuiDivider-fullWidth {
    width: 100%;
    margin: 5px auto;
  }
}

.MuiTableCell-root .MuiIconButton-root,
.MuiGrid-root .MuiIconButton-root {
  width: 35px !important;
  height: 35px !important;
}

.MuiDrawer-root {
  .MuiList-root {
    .MuiListItem-root {
      .MuiTypography-root {
        font-size: 1rem;
        font-weight: 400;
        font-family: 'Lato', sans-serif;
      }
    }
  }
}

.MuiTablePagination-toolbar {
  @media screen and (max-width: 768px) {
    padding: 0 !important;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.MuiFormControlLabel-root {
  &.Mui-disabled {
    display: none;
  }
}
